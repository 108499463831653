import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../models';

@Injectable({
  providedIn: 'root'
})
export class CompanyconfigService {

  constructor(private http: HttpClient) {
    console.log('CompanyconfigService listo');
  }

  getQuery( ) {

    //const URL = `https://misadapitesting.clavisco.com/api/Configs/GetSAPConfigs`;
    const URL =`${AppConstants.apiUrl}api/api/Configs/GetSAPConfigs/`


    return this.http.get(URL);
  }

  getCampanyConfigs() {

    return this.getQuery();

    // return this.getQuery('browse/new-releases?limit=20')
      // .pipe( map( data => data['albums'].items ));

  }

}
