import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../models';

@Injectable({
  providedIn: 'root'
})
export class SapconfigService {

  constructor(private http: HttpClient) {
    console.log('SapconfigService listo');
  }

  getQuery( query: string ) {

    //const URL = `https://misadapitesting.clavisco.com/api/Configs/GetSAPConfigs?dbName=${query}`;
    const URL =`${AppConstants.apiUrl}api/api/Configs/GetSAPConfigs?dbName=${query}`


    return this.http.get(URL);
  }

  getSapConfigs() {

    return this.getQuery('ZADUTEST');

    // return this.getQuery('ZSISLOTEST')
    //   .pipe( map( data => data['SAPConn'] ));

  }

}
